<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <span class="display-4 col-12">{{ pack.name }}</span>
    </div>

    <div class="row">
      <div class="col-9">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
          <dd class="col-sm-8">
            {{ pack.name }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EXCERPT") }}</dt>
          <dd class="col-sm-8">
            <div v-html="pack.excerpt"></div>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.PRICE") }}</dt>
          <dd class="col-sm-8">
            {{ $formatCurrency(pack.price) }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("PACKAGES.FREQUENCY") }}</dt>
          <dd class="col-sm-8">
            {{ pack.frequency }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("PACKAGES.MAXIMUM_USERS") }}</dt>
          <dd class="col-sm-8">
            {{ pack.maximum_users }}
          </dd>
        </dl>
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ROLES)"
        >
          <dt class="col-sm-4">{{ $t("PACKAGES.DEFAULT_ROLE") }}</dt>
          <dd class="col-sm-8">
            <router-link
              :to="{ name: 'View Role', params: { id: pack.default_role.id } }"
            >
              {{ `${pack.default_role.name}` }}
            </router-link>
          </dd>
        </dl>
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.RESELLER") }}</dt>
          <dd class="col-sm-8">
            <reseller :reseller="pack.reseller" />
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="pack.created_at">
            {{ $formatDate(pack.created_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="pack.updated_at">
            {{ $formatDate(pack.updated_at) }}
          </dd>
        </dl>
      </div>
      <div class="col-3">
        <img
          v-if="pack.picture"
          :src="`${pack.picture}`"
          class="argon-image mb-2"
          style="width: 100%"
        />
        <img v-else src="/img/placeholder.jpg" class="argon-image mb-2" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "package-view-global",

  components: {},

  mixins: [],

  props: ["pack"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    pack(pack) {},
  },
};
</script>
